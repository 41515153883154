import { useField, FormikErrors, useFormikContext } from "formik";
import { GeoField, GeoFieldSingleProps } from "shared/ui/GeoField";
import { Geocoding } from "shared/services/geo/GeoHelpers";
import { joinStrings } from "shared/utils/StringUtils";
import { dequal } from "dequal/lite";
import { CarrierPayVenueDTO } from "../../../data/ShippingDetailsDTO";

function mapGeocodeToVenue({
  postcode,
  place,
  region_short,
  region,
}: Partial<Geocoding>) {
  return {
    zip: postcode,
    city: place,
    state: region_short?.toLowerCase() || region?.toLowerCase(),
  };
}

function mapVenueToGeocode({
  zip,
  city,
  state,
}: CarrierPayVenueDTO): Partial<Geocoding> {
  return {
    postcode: zip || undefined,
    place: city || undefined,
    region_short: state?.toUpperCase(),
  };
}

function formatOptionLabel({
  postcode,
  place,
  region,
  region_short,
  country,
  country_short,
}: Partial<Geocoding>) {
  return joinStrings(
    ", ",
    place,
    joinStrings(" ", region_short || region, postcode),
    country_short || country
  );
}

interface VenueFieldProps extends Omit<GeoFieldSingleProps, "value" | "types"> {
  name: string;
}

export function VenueField({ name, TextFieldProps, ...rest }: VenueFieldProps) {
  const { isSubmitting } = useFormikContext();
  const [{ value }, { error, touched }, { setValue }] =
    useField<CarrierPayVenueDTO | null>(name);

  const venueError = error as
    | FormikErrors<CarrierPayVenueDTO>
    | string
    | undefined;
  const errorText = touched && venueError;

  return (
    <GeoField
      {...rest}
      fullWidth={true}
      disabled={isSubmitting}
      value={!value ? null : mapVenueToGeocode(value)}
      types={["place", "postcode"]}
      getOptionSelected={(option, val) => {
        // convert both items into same format
        return dequal(mapGeocodeToVenue(option), mapGeocodeToVenue(val));
      }}
      formatOptionLabel={formatOptionLabel}
      onChange={(updatedValue) => {
        setValue(!updatedValue ? null : mapGeocodeToVenue(updatedValue));
      }}
      TextFieldProps={{
        ...TextFieldProps,
        name,
        fullWidth: true,
        error: !!errorText,
        helperText:
          errorText &&
          (typeof errorText === "string"
            ? errorText
            : errorText.zip || errorText.city || errorText.state),
      }}
    />
  );
}
