import { get } from "lodash-es";
import { useQuery, UseQueryResult } from "react-query";
import createGeocodingClient, { FeatureType, GeocodingFeature, StandardForwardConfig, StructuredForwardConfig } from "@mapbox/mapbox-sdk/services/geocoding-v6";

import { logWarning } from "../MonitoringService";
import { trim } from "../../utils/StringUtils";

// Order Transport: pk.eyJ1Ijoic3VwZXJkaXNwYXRjaCIsImEiOiJjbGo0MjBpazYxanB6M25wOWN1NnI2Z2x4In0.VVJ5YfU2vOH9X8kj_ZDdJA
const ACCESS_TOKEN =
// Carrier Pay Calculator Web
  "pk.eyJ1Ijoic3VwZXJkaXNwYXRjaCIsImEiOiJjbGY5YnVpaGUyZXNjM3ZyMGF2ZWlheXlkIn0.GNel0j3DRt3ZlB8Yv9wh_Q";

const geocodingService = createGeocodingClient({
  accessToken: ACCESS_TOKEN,
});

export function useMapboxPredictions(
  query: string | undefined,
  types: FeatureType[] = [],
  countries: string[] = ["US"]
): UseQueryResult<Geocoding[], Error> {
  return useQuery(
    [
      "mapbox",
      "predictions",
      {
        query: trim(query), // trim in order to prevent multipe calls for extra whitespaces
        types,
        countries,
      },
    ],
    () => {
      if (!query) return [];

      return forwardGeocode({
        mode: "standard",
        types,
        countries,
        query,
        autocomplete: true,
        bbox: [ -179, 24.9493, -66.9326, 70], // USA only
      });
    },
    {
      enabled: !!query,
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
}

export function forwardGeocode(
  request: StandardForwardConfig | StructuredForwardConfig
): Promise<Geocoding[]> {
  return geocodingService
    .forwardGeocode({
      permanent: false,
      ...request,
    })
    .send()
    .then((res) => {
      return res.body.features.map(parseGeocodeFeature);
    })
    .catch((error) => {
      const mapboxError = createMapboxError(error);

      logWarning("Failed to search Mapbox", {
        mapboxError,
        request,
        original_error: error,
      });

      throw createMapboxError(mapboxError);
    });
}

function createMapboxError(error: unknown) {
  if (error instanceof Error) {
    return error;
  }

  const errorMessage = get(error, "message");
  if (typeof errorMessage === "string") {
    return new Error(errorMessage);
  }

  return new Error("Failed to execute Geo service queries.");
}

export const GEOCODING_KEYS = [
  "address",
  "country",
  "country_short",
  "house_number",
  "locality",
  "place", // city
  "postcode",
  "region", // state
  "region_short",
  "street",
  "neighborhood",
  "district",
] as const;
export type GeocodingKey = typeof GEOCODING_KEYS[number];

export type Geocoding = {
  longitude: number;
  latitude: number;
  relevance?: number;
} & {
  [TKey in GeocodingKey]?: string;
};

export function parseGeocodeFeature(geocodeFeature: GeocodingFeature): Geocoding {
  const { context, coordinates  } = geocodeFeature.properties;

  const geocoding: Geocoding = {
    longitude: coordinates.longitude,
    latitude: coordinates.latitude,
  };

  if (context.country) {
    geocoding.country = context.country.name;
    geocoding.country_short = context.country.country_code;
  }

  if (context.region) {
    geocoding.region = context.region.name;
    geocoding.region_short = context.region.region_code;
  }

  if (context.place) {
    geocoding.place = context.place.name;
  }

  if (context.neighborhood) {
    geocoding.neighborhood = context.neighborhood.name;
  }

  if (context.locality) {
    geocoding.locality = context.locality.name;
  }

  if (context.postcode) {
    geocoding.postcode = context.postcode.name;
  }

  if (context.street) {
    geocoding.street = context.street.name;
  }

  if (context.address) {
    geocoding.address = context.address.name;
    geocoding.house_number = context.address.address_number;
    geocoding.street = context.address.street_name;
  }

  return geocoding;
}
