import { Tooltip } from "@material-ui/core";
import { Color, Inline, Tag } from "@superdispatch/ui";
import { TextBox } from "@superdispatch/ui-lab";
import styled from "styled-components";

function getConfidenceColor(score: number): "red" | "yellow" | "green" {
  if (score < 60) return "red";
  if (score < 85) return "yellow";
  return "green";
}

function getConfidenceText(score: number): string {
  if (score < 60) return "Low";
  if (score < 85) return "Moderate";
  return "High";
}

function getConfidenceTooltip(score: number) {
  if (score < 60)
    return (
      <span>
        This recommendation is a starting point based on limited data.
        <br />
        <br />
        For more accurate pricing, consider reviewing similar orders in Recent
        Moves or Super Loadboard below before finalizing your price.
      </span>
    );

  if (score < 85)
    return (
      <span>
        This estimate is based on a fair amount of data and provides a
        reasonable starting point.
        <br />
        <br />
        For increased accuracy, consider cross-referencing similar orders in
        Recent Moves or Super Loadboard below before making a final decision.
        before making a final decision.
      </span>
    );

  return (
    <span>
      This estimate is backed by comprehensive market data, providing a highly
      reliable recommendation.
      <br />
      <br />
      Minimal additional review is needed, but you can verify similar orders in
      Recent Moves or Super Loadboard if desired.
    </span>
  );
}

const ConfidenceBox = styled.div`
  cursor: default;
  border-bottom: 1px ${Color.Silver500} dashed;
`;

export function ConfidenceScore({ confidence }: { confidence: number }) {
  return (
    <Inline space="xxsmall">
      <Tag color={getConfidenceColor(confidence)} variant="subtle">
        {confidence}%
      </Tag>
      <Tooltip title={getConfidenceTooltip(confidence)}>
        <ConfidenceBox>
          <TextBox variant="body-semibold">
            {getConfidenceText(confidence)} Confidence
          </TextBox>
        </ConfidenceBox>
      </Tooltip>
    </Inline>
  );
}
